export const it = {
  save: 'Salva',
  update: 'Aggiorna',
  accept: 'Accetta',
  cancel: 'Annulla',
  close: 'Chiudi',
  add: 'Aggiungi',
  check: 'Controlla',
  buttons: 'Pulsanti',
  calendar: 'Calendario',
  components: 'Componenti',
  certificate: 'Certificato',
  certificates: 'Certificati',
  certification: 'Certificazione',
  certifications: 'Certificazioni',
  'valid-certificate': 'Certificato valido',
  date: 'Data',
  'valid-thru': 'Valido fino a',
  dashboard: 'Home page',
  dtables: 'Tabelle dati',
  eforms: 'Moduli estesi',
  error: 'Pagina di errore',
  forms: 'Moduli',
  maps: 'Mappe',
  notifications: 'Notifiche',
  pages: 'Pagine',
  plan: 'Scegliere il piano',
  pricing: 'Prezzi',
  'my-profile': 'Il mio profilo',
  'register-headline': 'Registrazione',
  register: 'Registrarsi',
  search: 'Ricerca',
  settings: 'Impostazioni',
  tables: 'Tabelle',
  tabs: 'Schede',
  myeval: 'EvalCard',
  'eval-id': 'Eval-ID',
  id: 'ID',
  timeline: 'Timeline',
  typography: 'Tipografia',
  admin: 'Amministratore',
  user: 'Utente',
  users: 'Utente',
  username: 'Nome utente',
  'username-or-email': 'Nome utente o e-mail',
  'user-profile': 'Profilo utente',
  'company-name': 'Nome della società',
  'company-logo': "Logo dell'azienda",
  'company-profile': 'Profilo aziendale',
  'company-profile-admin': 'Profilo aziendale',
  'company-invitation': "Invito dell'azienda",
  'invitation-sent': 'Invito inviato',
  'new-password': 'Nuova password',
  'generate-new-password': 'Genera nuova password',
  'current-password': 'Password attuale',
  'repeat-password': 'Ripeti password',
  'password-successfully-changed': 'La password è stata modificata con successo',
  'password-reset': 'Ripristina password',
  'edit-user-profile': 'Modifica profilo utente',
  'edit-user-profile-admin': 'Modifica profilo utente',
  system: 'Sistema',
  vforms: 'Moduli di convalida',
  widgets: 'Widget',
  wizard: 'Procedura guidata',
  logout: 'Esci',
  login: 'Accedi',
  companies: 'Aziende',
  'my-company': 'La mia azienda',
  'leave-company': "Esci dall'azienda",
  'delete-company': 'Elimina azienda',
  company: 'Azienda',
  uid: 'P.IVA/partita iva',
  employees: 'Dipendenti',
  employee: 'Dipendente',
  companyadmin: 'Admin',
  evalclient: 'Rappresentante autorizzato EVAL',
  coworker: 'Dipendente',
  'coworker-profile': 'Profilo del dipendente',
  trainer: 'Formatore',
  authorityAdmin: 'Admin',
  trainerRO: 'Formatore (solo lettura)',
  authorityAdminRO: 'Amministratore (solo lettura)',
  protocols: 'Rapporti di prova',
  'all-protocols': 'Tutti i rapporti di prova',
  report: 'Rapporto di prova',
  'new-report': 'Nuovo rapporto di prova',
  reports: 'Rapporti di prova',
  'all-reports': 'Tutti i rapporti di prova',
  'report-from': 'Rapporto di prova daRapporto di prova daRapporto di prova da',
  'report-details': 'Dettagli rapporto di prova',
  data: 'Dati',
  info: 'Info',
  attachment: 'Allegato',
  contact: 'Contatto',
  continue: 'Continuare',
  summary: 'Riepilogo',
  vin: 'VIN',
  'licence-plate': 'Numero di targa',
  owner: 'Proprietario',
  vehicles: 'Veicoli',
  vehicle: 'Veicolo',
  'vehicle-details': 'Dettagli del veicolo',
  companyVehicle: 'Veicolo aziendale',
  valid: 'Valido',
  invalid: 'Non valido',
  tester: 'Esaminatore',
  'vehicles-admin': 'Veicoli',
  'create-company-vehicle': 'Crea veicolo',
  'select-vehicle': 'Selezione veicolo',
  'my-vehicles': 'I miei veicoli',
  'my-vehicle': 'Il mio veicolo',
  'new-vehicle': 'Nuovo veicolo',
  manufacturer: 'Costruttore',
  'no-manufacturer': '(Nessun costruttore)',
  'no-vehicle': '(Nessun veicolo)',
  'vehicle-manufacturer': 'Costruttore del veicolo',
  'create-vehicle': 'Crea veicolo',
  'create-vehicle-manufacturer': 'Creare il costruttore del veicolo',
  'construction-year': 'Anno di costruzione',
  'change-password': 'Modifica password',
  roles: 'Autorizzazioni',
  actions: 'Azioni',
  action: 'Azione',
  profile: 'Profilo',
  email: 'E-mail',
  basic: 'Base',
  rights: 'Diritti',
  details: 'Dettagli',
  information: 'Informazioni',
  name: 'Nome',
  namename: 'Denominazione',
  firstname: 'Nome',
  lastname: 'Cognome',
  fullname: 'Nome completo',
  password: 'Password',
  street: 'Via',
  number: 'Numero',
  city: 'Città',
  country: 'Paese',
  'forgot-password': 'Hai dimenticato la password?',
  'recover-password': 'Reimposta password',
  back: 'Indietro',
  'back-to-login': 'Torna al login',
  'go-to-login': 'Vai al login',
  'choose-picture': 'Seleziona immagine',
  'choose-logo': 'Seleziona logo',
  'send-invitation': 'Invia invito',
  zipcode: 'CAP',
  previous: 'Indietro',
  abort: 'Annulla',
  'i-understand': 'Capito',
  YES: 'Sì',
  no: 'No',
  yes: 'Sì',
  inputs: 'Ingressi',
  'user-activated': 'Attivato',
  'user-not-activated': "L'utente non è ancora attivato",
  'user-did-not-receive-activation-email': "L'utente non ha ancora ricevuto un'e-mail di attivazione",
  'allow-coworkers-to-show-on-profile': 'Mostra sul profilo utente dei dipendenti:',
  'resend-activation-email': 'Richiesta link di attivazione',
  'coworker-education-status': 'Stato di formazione dei dipendenti',
  card: 'Biglietto da visita',
  'certification-authority': 'Centro di certificazione',
  'certification-authority-admin': 'Centro di certificazione',
  'certification-authorities': 'Centri di certificazione',
  'delete-certification-authority': 'Cancella autorità di certificazione',
  'certificate-templates': 'Modelli di certificato',
  'certificate-template': 'Modello di certificato',
  'creating-certificates': 'I certificati vengono creati...',
  'issue-certificate': 'Rilascio del certificato',
  'issue-date': 'Data di emissione',
  'due-date': 'Data di scadenza',
  'issued-on': 'Rilasciato il',
  'issued-by': 'Rilasciato da',
  author: 'Emittente',
  'certificate-name': 'Nome del certificato',
  'certificate-number': 'Numero del certificato',
  'born-on': 'Nato il',
  mileage: 'Chilometraggio',
  public: 'Pubblico',
  'back-to-the-app': "Torna all'app",
  'can-create-reports': 'Può creare rapporti',
  'valid-for': 'Valido per (anni)',
  'pdf-template': 'Modello PDF',
  'pdf-certificate': 'Certificato PDF proprio',
  'show-pdf-certificate': 'Certificato in formato PDF',
  standards: 'Standard (HTML)',
  'text-block': 'Testo conforme agli standard (HTML)',
  'digital-signature': 'Firma digitale',
  eval1: 'EVAL-1',
  eval2: 'EVAL-2',
  eval3: 'EVAL-3',
  others: 'Altro',
  level: 'Livello',
  type: 'Tipo',
  'training-data': 'Documenti di formazione',
  'training-info': 'Informazioni sulla formazione',
  'generated-password': 'Password generata',
  'invitation-email': 'Email di invito',
  document: 'Documento',
  documents: 'Documenti',
  active: 'attivo',
  inactive: 'inattivo',
  all: 'TUTTI',
  'all-sort': 'Tutti tipi',
  'created-on': 'Creato il',
  result: 'Risultato',
  results: 'Risultati',
  'try-again': 'Riprova',
  status: 'Stato',
  sn: 'SN',
  'something-went-wrong': 'qualcosa non va',
  'info-signs': 'Cartelli informativi',
  danger: 'Attenzione',
  'hazardous-voltage': 'Tensione elettrica pericolosa',
  'no-access-1': 'VIETATO L´ACCESSO',
  'no-access-2': 'ALLE PERSONE NON AUTORIZZATE',
  'access-only-by': 'Accesso solo tramite',
  'company-logo-is-missing': 'Manca il logo aziendale',
  download: 'Scaricare',
  'access-denied': 'Accesso vietato',
  'dont-switch-on': 'Non accendere',
  'hv-system': 'SISTEMA HV',
  disconnected: 'DISINSERITO',
  'disconnected-by': 'DISINSERITO da',
  'works-in-progress': 'Lavori in corso',
  forum: 'Forum EV',
  topic: 'Argomento',
  topics: 'Temi',
  title: 'Titolo',
  created: 'Creato',
  from: 'da',
  answers: 'Risposte',
  'post-answer': 'Risposta al messaggio',
  'edit-answer': 'Modifica la risposta',
  'new-topic': 'Nuovo argomento',
  'edit-topic': 'Modifica argomento',
  'create-new-topic': 'Creare un nuovo argomento',
  'last-answer': 'Ultima risposta',
  'view-all': 'Visualizza tutti',
  attachments: 'Allegati',
  'newest-topics': 'Ultimi argomenti',
  'privacy-policy': 'Protezione dei dati',
  'new-files': 'Nuovi file',
  'existing-files': 'File esistenti',
  dialog: {
    'are-you-sure': 'Sei sicuro?',
    'an-unexpected-error-occured': 'Si è verificato un errore. Riprovare più tardi',
    'do-you-really-want-the-user-to': "Vuoi davvero l'utente",
    'do-you-really-want-the-invitation-to': "Vuoi davvero l'invito",
    delete: 'Elimina',
    'delete-user': 'Elimina utente',
    'delete-topic': "Cancellare l'argomento",
    'delete-topic-answer': 'Cancellare la risposta',
    'remove-user': 'Rimuovi utente',
    'remove-trainer': 'Rimuovere il recensore',
    'remove-from-company': "Rimuovere dall'azienda?",
    'remove-from-certification-authority': "Rimuovere dalla certificazione l'autorità?",
    'create-user': 'Crea utente',
    'the-most-important-information': 'Le informazioni più importanti',
    'create-company': 'Crea azienda',
    'the-most-important-information-about-the-company': 'Le informazioni aziendali più importanti',
    'create-company-vehicle': 'Creare veicolo aziendale',
    'the-most-important-information-about-the-vehicle': 'Le informazioni più importanti sul veicolo',
    'create-report': 'Crea rapporto di prova',
    'where-is-the-company-located': "Dove si trova l'azienda?",
    'where-is-the-certification-authority-located': "dove si trova l'autorità di certificazione?",
    'whats-the-role-of-the-person': 'Qual è la funzione della persona?',
    'where-does-the-person-live': 'Dove vive la persona?',
    'further-details': 'Ulteriori dettagli',
    'register-successfull': "La registrazione è andata a buon fine! Riceverà a breve un'e-mail con la sua password",
    'great-you-will-receive-an-email': "Riceverà a breve un'e-mail per reimpostare la password",
    'this-should-not-take-longer-than':
      'Questa operazione non dovrebbe richiedere più di 5 minuti. In caso contrario, controllare anche la cartella spam',
    'i-agree': "Sono d'accordo con il",
    to: 'a',
    'terms-and-conditions': 'Termini e condizioni generali',
    'hereby-i-agree':
      "Accetto che Evalus GmbH utilizzi i miei dati personali per l'emissione di una carta EVAL e per la registrazione dell'utente su my.evalcard.com",
    'further-i-agree-to':
      "Inoltre, acconsento che Evalus GmbH possa informarmi tramite newsletter via e-mail sulle novità del settore dell'alta tensione (nuovi corsi di formazione, nuovi prodotti o eventi) fino a nuovo avviso",
    'allowed-to-be-used': '(.de o .com) può essere utilizzato',
    'refusal-is-allways': 'La cancellazione è possibile in qualsiasi momento via e-mail a',
    possible: 'possibile',
    'the-e-val-card-is-the':
      "La carta EVAL è il certificato di qualificazione in formato check card. L'abbreviazione EVAL sta per Electric Vehicle Access Licence",
    'complete-user-data': 'Completare da utente',
    'complete-user-data-info':
      'Per potervi fornire il miglior supporto possibile e per potervi mettere a disposizione tutte le funzioni, abbiamo bisogno di un profilo utente completo da parte vostra',
    'your-password-reset-is-being-processed':
      'La password viene reimpostata in questo momento. Se il processo dura più di qualche minuto, si prega di riprovare',
    'your-password-has-been-sent':
      "La password è stata reimpostata con successo. Dovrebbe ricevere un'e-mail con la nuova password entro pochi minuti",
    'if-it-did-not-work':
      'Si prega di controllare anche la cartella spam. Se non ha ricevuto la nuova password entro 5-10 minuti, la preghiamo di riprovare',
    'user-not-authorized': 'Nome utente e/o password non corretti',
    'user-not-activated':
      "Questo utente non è ancora stato attivato. Utilizzare il link contenuto nell'e-mail di attivazione",
    'user-or-password-wrong': 'Il nome utente o la password non sono corretti',
    'users-selected': 'Utente selezionato',
    'user-profile-update-successfull': 'Il profilo utente è stato salvato con successo',
    'user-profile-update-failed': 'Non è stato possibile salvare il profilo utente. Riprovare più tardi',
    'do-you-really-want-to-leave-the-company': "Sei sicuro di voler uscire dall'azienda?",
    'in-order-to-accept-a-company-invitation':
      "Per accettare l'invito di un'azienda, è necessario essere già registrati e aver effettuato l'accesso",
    'do-you-really-want-to-join-the-company': "Sei sicuro di volerti iscrivere all'azienda?",
    'if-you-are-already-in-a-company-you-will-be-removed': "Se siete già in un'azienda, verrete rimossi da essa",
    'you-are-not-a-member-of-a-company':
      "Al momento non sei assegnato a un'azienda. Non appena sarai membro di un'azienda, potrai utilizzare questa pagina",
    'you-are-not-a-member-of-a-certification-authority':
      'Al momento non siete assegnati all´autorità di certificazione',
    'do-you-really-want-the-company-to': "Volete davvero l'azienda",
    'company-created-successfully': "L'azienda è stata creata con successo",
    'company-already-exists':
      "Un'azienda con questo nome o email esiste già. Pertanto non è stato possibile creare l'azienda",
    'vehicle-save-successfully': 'Il veicolo è stato salvato con successo',
    'vehicle-already-exists': 'Il veicolo con questo VIN e proprietario esiste già',
    'vehicle-accepted': 'Il veicolo è stato aggiunto al sistema',
    'vehicle-manufacturer-accepted': 'Il produttore del veicolo è stato aggiunto al sistema',
    'report-save-successfully': 'Il rapporto di ispezione è stato salvato con successo',
    'report-already-exists': 'Un rapporto di prova con questo nome e tipo esiste già in questa azienda',
    'create-company-failed': "Non è stato possibile creare un'azienda. Riprovare più tardi",
    'save-user-success': "L'utente è stato salvato con successo",
    'user-already-exists': 'Esiste già un utente con questo nome o e-mail',
    'save-company-success': "L'azienda è stata salvata con successo",
    'save-company-failed': 'Non è stato possibile salvare la società. Riprovare più tardi',
    'save-changes-success': 'Le modifiche sono state salvate con successo',
    'do-you-really-want-the-vehicle-to': 'Vuole davvero il veicolo',
    'do-you-really-want-the-report-to': 'Vuole davvero il rapporto di prova',
    'do-you-really-want-the-certificate-to': 'Vuole davvero il certificato',
    'do-you-really-want-the-vehicle-manufacturer-to': 'Volete davvero il costruttore del veicolo',
    'save-vehicle-manufacturer-success': 'Il costruttore del veicolo è stato salvato con successo',
    'save-vehicle-manufacturer-error': 'Non è stato possibile salvare il costruttore del veicolo',
    'save-vehicle-success': 'Il veicolo è stato salvato con successo',
    'save-vehicle-error': 'Non è stato possibile salvare il veicolo',
    'who-works-in-the-company': 'Chi lavora in azienda?',
    'who-works-in-the-certification-authority': "Chi lavora presso l'autorità di certificazione?",
    'your-company-has-been-created-check-your-emails':
      "La vostra azienda è stata creata! Dovrebbe ricevere a breve un'e-mail con la quale potrà attivare la sua azienda! Se necessario, controllate la cartella spam",
    'if-you-havent-received-an-email-yet': "Se non ha ricevuto l'e-mail entro 5 minuti, può richiederla nuovamente qui",
    'if-this-did-not-work': 'Se non ha ancora funzionato, può anche lasciare la sua azienda e crearne una nuova',
    'is-already-in-a-company': "è già in un'azienda",
    'invite-user-already-exists-in-company': "L'utente con questa email è già in azienda",
    'invite-user-already-exists-in-other-company': "L'utente con questo indirizzo e-mail è già in un'altra azienda",
    'create-certification-authority': 'Crea autorità di certificazione',
    'certification-authority-save-successfully': "L'autorità di certificazione è stata salvata con successo",
    'certification-authority-already-exists':
      "Esiste già un'autorità di certificazione con questo indirizzo e-mail o nome",
    'certificate-already-exists': 'Per questo utente esiste già una certificazione con questo nome',
    'certificate-save-successfully': 'La certificazione è stata salvata con successo',
    'do-you-really-want-the-certification-authority-to': "Si desidera davvero l'autoritá di certificazione",
    'create-certificate': 'Crea certificato',
    'create-certificate-template': 'Crea modello di certificato',
    'who-gets-the-certificate': 'A chi deve essere rilasciato il certificato',
    'user-with-this-eval-id-was-not-found': "L'utente con questo ID Eval non è stato trovato",
    'you-can-only-add-reports-if-you-have-company-vehicles':
      'È possibile creare un rapporto solo se si possiedono veicoli aziendali',
    'you-can-only-input-eu-numbers': 'Sono possibili solo numeri in formato UE',
    'you-dont-have-the-rights': "Nessuna autorizzazione per quest'area",
    'certificate-template-save-success': 'Il modello di certificato è stato salvato con successo',
    'certificate-template-sort-save-success': "L'ordinamento dei modelli di certificato è stato salvato con successo",
    'your-certification-authority-does-not-have-templates':
      'Si prega di creare prima un modello di certificato per poter emettere i certificati',
    'this-company-is-not-yet-activated':
      "Questa azienda non è ancora stata attivata. Il link di attivazione è stato inviato all'indirizzo e-mail dell'azienda",
    'welcome-to-the-dashboard':
      "Benvenuto nella home page! Non sei ancora assegnato a un'azienda. Non appena sarai membro di un'azienda, potrai vedere qui una panoramica dei tuoi protocolli",
    'valid-certificates': 'Certificati validi',
    'certificates-last-30-days': 'Certificati - ultimi 30 giorni',
    'reports-last-30-days': 'Rapporti di prova - ultimi 30 giorni',
    'no-data-available': 'Nessun dato ancora disponibile',
    'this-manufacturer-does-not-exist':
      'Questo produttore non è ancora presente nel sistema. Verrà creato automaticamente',
    'this-vehicle-does-not-exist': 'Questo veicolo non è ancora presente nel sistema. Verrà creato automaticamente',
    'required-to-complete-registration': 'È necessario il consenso per completare la registrazione',
    'username-is-already-used': 'Questo nome utente è già stato assegnato',
    'email-is-already-used': 'Questo indirizzo e-mail è già stato assegnato',
    'enter-valid-birthday-date': 'Si prega di inserire una data di nascita valida',
    'enter-valid-eu-number-format': 'Si prega di utilizzare solo numeri in formato UE',
    'only-use-numbers-with-or': "Si prega di utilizzare solo numeri e '-' o '/'",
    'this-user-is-already-in-this-certification-authority':
      'Questo utente è già presente in questa autorità di certificazione',
    'this-user-is-already-selected': 'Questo utente è già selezionato',
    'this-user-is-already-in-a-company': "Questo utente fa già parte di un'azienda",
    'the-document-could-not-be-loaded': 'Non è stato possibile caricare il documento',
    '': '',
    "continue": "Continuare",
    "finish": "Finitura"
  },
  tooltip: {
    'csv-export': 'Esportazione CSV',
    'add-user': 'Aggiungi utente',
    'invite-user': 'Invita utente',
    'delete-user': 'Elimina utente',
    'remove-user': 'Rimuovi utente',
    'edit-user': 'Modifica utente',
    'view-user': 'Visualizza utente',
    'send-invitation-again': 'Reinvio invito',
    'send-activation-again': 'Reinvio del link di attivazione',
    'admin-cant-leave-company': "In funzione di rappresentante eval, non è possibile uscire dall'azienda",
    'leave-company': "Uscire dall'azienda",
    'add-company': 'Aggiungi azienda',
    'edit-company': 'Modifica azienda',
    'delete-company': 'Elimina azienda',
    'add-selected-users-to-company': "Aggiungi utenti selezionati a un'azienda",
    'add-vehicle': 'Aggiungi veicolo',
    'show-vehicle': 'Dettagli veicolo',
    'edit-vehicle': 'Modifica veicolo',
    'view-vehicle': 'Visualizza veicolo',
    'accept-vehicle': 'Trasferire il veicolo al sistema',
    'accept-vehicle-manufacturer': 'Trasferire il costruttore del veicolo al sistema',
    'delete-vehicle': 'Elimina veicolo',
    'add-vehicle-manufacturer': 'Aggiungere il costruttore del veicolo',
    'edit-vehicle-manufacturer': 'Modifica del costruttore del veicolo',
    'delete-vehicle-manufacturer': 'Cancellare il costruttore del veicolo',
    'vehicle-details-missing': 'Mancano i dati del veicolo',
    'remove-invitation': 'Cancella invito',
    'send-missing-activations': 'Invia le e-mail di attivazione mancanti',
    'create-report': 'Crea rapporto di prova',
    'select-for-report': 'Seleziona per il rapporto di prova',
    'add-question': 'Aggiungi campo',
    'remove-question': 'Rimuovi campo',
    'create-certificates': 'Crea certificati',
    'view-certificate': 'Visualizza certificato',
    'delete-certificate': 'Elimina certificato',
    'view-report': 'Visualizza rapporto di prova',
    'delete-report': 'Elimina rapporto di prova',
    'no-pdf-found': 'Non è stato trovato alcun documento PDF',
    'add-report': 'Aggiungi rapporto di prova',
    'add-certificate': 'Crea certificato',
    'no-certificate-to-create-report': 'Non avete un certificato adeguato',
    'add-certification-authority': 'Crea autoritá di certificazione',
    'edit-certification-authority': 'Modifica autorità di certificazione',
    'delete-certification-authority': 'Elimina autorità di certificazione',
    'add-certificate-template': 'Crea modello di certificazione',
    'edit-certificate-template': 'Modifica modello di certificazione',
    'delete-certificate-template': 'Elimina modello di certificazione',
    'view-certificate-training-data': 'Visualizza documenti di formazione',
    'save-sorting': 'Salva ordinamento',
    'mark-user': 'Seleziona utente',
    'open-topic': 'Visualizza argomento',
    'edit-topic': 'Modifica argomento',
    'delete-topic': 'Cancella argomento',
    'edit-topic-answer': 'Modifica risposta',
    'delete-topic-answer': 'Cancella risposta',
    'select-all-users': 'Seleziona tutti gli utenti',
  },
  phone: 'Numero di telefono',
  address: 'Indirizzo',
  birthday: 'Data di nascita',
  'profile-picture': 'Immagine del profilo',
  userprofile: {
    'edit-profile': 'Modifica profilo',
    'public-profile': 'Profilo pubblico',
    'following-information': 'Le seguenti informazioni vengono visualizzate nel profilo pubblico',
    "notification-settings": "Se l'azienda non invia notifiche, questa impostazione non ha alcun effetto."
  },
  'training-director': 'Istruttore di formazione',
  'participated-the-training': 'ha partecipato alla formazione',
  'result-protocol': 'Registro dei risultati',
  measurements: 'Valori misurati',
  freischaltung: 'Disattivazione',
  isolationswiderstand: 'Resistenza di isolamento',
  potentialausgleich: 'Equalizzazione del potenziale',
  disconnection: 'Disinserimento',
  'isolation-resistance': 'Resistenza di isolamento',
  'potential-equalization': 'Equalizzazione del potenziale',
  r: {
    'measuring-instrument-functionality': 'Funzionalità dello strumento di misurazione',
    'measuring-instrument-functionality-tested': 'Strumento di misurazione funzionalità testata',
    'positive-test-done': 'Test positivo eseguito',
    'voltage-between-hv-plus-and-chassis': 'Tensione tra HV+ e telaio',
    'voltage-between-hv-plus-and-hv-minus': 'Tensione tra HV+ e HV-',
    'voltage-between-hv-minus-and-chassis': 'Tensione tra HV- e telaio',
    'the-tester-accepts-the-security-instructions':
      "L'ispettore conferma la conformità alle istruzioni di sicurezza del produttore",
    'measuring-instrument-tested-at-reference-source':
      'Controllo del funzionamento dello strumento di misurazione su una fonte di riferimento?',
    'measuring-instrument-tested': 'Funzionamento dello strumento testato?',
    'absence-of-voltage': 'Nessuna tensione presente',
    'absence-of-voltage-confirmed': 'Nessuna tensione rilevata',
    voltage: 'Tensione (V)',
    'used-voltage': 'Tensione utilizzata',
    'used-test-voltage': 'Tensione di prova applicata',
    'isolation-standard': 'Valore di riferimento della resistenza di isolamento',
    'resistance-per-volt': 'Resistenza per volt',
    'hv-system-voltage': 'Tensione di esercizio HV / Tensione di sistema HV',
    'isolation-resistance-between-hv-plus-and-chassis': 'Resistenza di isolamento tra HV+ e telaio',
    'isolation-resistance-between-hv-minus-and-chassis': "Resistenza d'isolamento tra HV- e telaio",
    'target-isolation-resistance-value': 'Valore di riferimento della resistenza di isolamento',
    'further-measurements-if-needed': 'Ulteriore misurazione se richiesta',
    'test-current': 'Corrente di prova (A)',
    'test-current-q': 'Corrente di prova ...',
    'measuring-point': 'Punto di misura',
    'measuring-point-chassis-vs': 'Punto di misura telaio contro ...',
    'converter-housing': "Alloggiamento dell'inverter",
    'further-voltage-measurements-if-needed': 'Ulteriore misurazione della tensione, se desiderata',
    'measurements-ok-if-smaller-than-1-v': 'Risultato della misura positivo se la tensione misurata è inferiore a 1V',
    'measuring-instrument': 'Funzione del strumento di misura',
  },
  'new-report-no-id': 'Nuovo rapporto di prova',
  'required-field': 'Questo campo è obbligatorio',
  'fill-out-all-required-fields': 'Compilare tutti i campi obbligatori!',
  'valid-email-only': 'Inserire un indirizzo e-mail valido.',
  'certificate-notification': 'Notifiche di certificati',
  'certificate-notification-company': 'In questo modo è possibile disattivare le notifiche per tutti i dipendenti.'
}
